//
// page-head.scss
//

.page-title-box {
    .page-title {
        font-size: 18px;
        margin: 0;
        line-height: 75px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: rgba(0, 0, 0);
    }
    .page-title-right {
        float: right;
        margin-top: 20px;

        .breadcrumb-endpoint {
            color: rgba(0, 0, 0);
        }
    }

    .breadcrumb {
        padding-top: 8px;
    }
}

.page-subtitle {
    color: #050505;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
}

.page-subtitle-text {
    font-weight: 400;
    color: #737579;
    font-size: 14px;
}

.page-subtitle-child-left {
    display: flex;
    align-items: left;
    justify-content: flex-start;
}

.page-subtitle-child-right {
    display: flex;
    align-items: right;
    justify-content: flex-end;
}

.page-title-box-sm {
    .page-title {
        line-height: 1 !important;
        margin-bottom: 25px;
    }
    .page-title-right {
        float: right;
        margin-top: 0;
    }

    .breadcrumb {
        padding-top: 0;
        margin-top: -3px !important;
    }
}

.text-title {
    color: $text-title-color;

    &:hover {
        color: $text-title-color;
    }
}

@include media-breakpoint-down(md) {
    .page-title-box {
        .page-title {
            display: block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            line-height: 70px;
        }
        .breadcrumb {
            display: none;
        }
        .page-title-right {
            display: none;
        }
    }
}

@media (max-width: 419px) {
    .page-title-box .breadcrumb {
        display: none;
    }
}

// Dark mode
body[data-layout-color="dark"] {
    .page-subtitle {
        color: rgba(255, 255, 255);
    }

    .page-title {
        color: rgba(255, 255, 255);
    }

    .page-title-right {
        .breadcrumb-endpoint {
            color: rgba(255, 255, 255);
        }
    }
}
