//
// Poppins fonts
//

@font-face {
    font-family: 'Poppins';
    src: url('../../../fonts/Poppins-Light.eot');
    src: local('Poppins Light'), local('Poppins-Light'), url('../../../fonts/Poppins-Light.eot?#iefix') format('embedded-opentype'), url('../../../fonts/Poppins-Light.woff') format('woff'), url('../../../fonts/Poppins-Light.ttf') format('truetype'), url('../../../fonts/Poppins-Light.svg#Roboto') format('svg');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Poppins';
    src: url('../../../fonts/Poppins-Regular.eot');
    src: local('Poppins Regular'), local('Poppins-Regular'), url('../../../fonts/Poppins-Regular.eot?#iefix') format('embedded-opentype'), url('../../../fonts/Poppins-Regular.woff') format('woff'), url('../../../fonts/Poppins-Regular.ttf') format('truetype'), url('../../../fonts/Poppins-Regular.svg#Roboto') format('svg');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Poppins';
    src: url('../../../fonts/Poppins-SemiBold.eot');
    src: local('Poppins SemiBold'), local('Poppins-SemiBold'), url('../../../fonts/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'), url('../../../fonts/Poppins-SemiBold.woff') format('woff'), url('../../../fonts/Poppins-SemiBold.ttf') format('truetype'), url('../../../fonts/Poppins-SemiBold.svg#Roboto') format('svg');
    font-weight: 600;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Poppins';
    src: url('../../../fonts/Poppins-Bold.eot');
    src: local('Poppins Bold'), local('Poppins-Bold'), url('../../../fonts/Poppins-Bold.eot?#iefix') format('embedded-opentype'), url('../../../fonts/Poppins-Bold.woff') format('woff'), url('../../../fonts/Poppins-Bold.ttf') format('truetype'), url('../../../fonts/Poppins-Bold.svg#Roboto') format('svg');
    font-weight: 700;
    font-style: normal;
  }