// NEW STYLES
$titleBottomLineColor: #E8E8EA;
$titleBackgroundColor: #FFFFFF;
$titleFontColor: #1E1E1E;
$inputBorderStrokeColor: #DFDFDF;
$neutralBorderColor: rgba(208, 209, 214, 0.86);
$iconGrayColor: rgb(172, 172, 172);
$tableHeaderBorderColor: #D0D1D6;

//////////////////////////////////////////////////////////////////////////////

.map_list_header {
  width: 480px;
  max-width: 480px;
  min-width: 320px;
}

.container_map {
  display: flex;
  height: 100vh;
}

.controls_map {
  width: 20%;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.map_object {
  width: 100%;
  height: 100vh;
}

.map_task_view {
  padding: 5px;
  margin-top: 10px;
}

.map_task_label {
  font-size: 10px;
}

.map_task_text {
  font-size: 16px;
}

.map_task_message {
  font-size: 20px;
  margin-bottom: 15px;
}

.marker_label_style {
  width: 16px;
  height: 12px;
  border: 1px solid rgba(0, 0, 0, 0.45);
  color: rgb(0, 0, 0) !important;
  background: rgba(255, 221, 0, 0.83);
  border-radius: 12%;
  text-align: center;
  line-height: 11.5px;
  font-weight: normal;
  font-size: 9px;
  transform: translate(13px,0);

  position: absolute;
  pointer-events: none;
  white-space: nowrap;
}

.vehicle_label_style {
  font-size: 10px !important;
  background: rgba(0, 255, 51, 0.36);
  transform: translate(-0.7px, -4.3px);
  font-family: "Nunito", sans-serif;
  -webkit-text-stroke: 1px rgba(0, 0, 0, 0.04);

  width: 22px;
  height: 22px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vehicle_label_style_for_tasks {
  @extend .vehicle_label_style;
  transform: translate(-0.7px, -6.5px);
  font-family: "Nunito", sans-serif;

  width: 22px;
  height: 22px;
}

.marker_card_style {
  background: rgba(52, 255, 15, 0.48);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);
  border: 1px solid rgba(52, 255, 15, 0.52);
}

/********************************************************/
.card_map_wrapper {
  min-height: 200px;
  min-width: 220px;
  padding-left: 1px;
  //height: calc(100vh - 218px);
}

.card_map_details {
  @extend .card_map_wrapper;
  height: 100%;
  background-color: rgba(255, 0, 0, 0);
}

.basic_details_container {
  min-width: 180px;
  width: 680px;
  max-width: 700px;
  padding-left: 0;
}

.driver_details_content {
  @extend .basic_details_container;
  height: calc(100vh - 550px);
}

.map_list_content{
  @extend .card_map_wrapper;
  max-height: calc(100vh - var(--collapsible-height));
  height: calc(100vh - var(--collapsible-height));

  background-color: transparent;
  padding-bottom: 3px;
  padding-right: 0;
  width: 100%;

  transition: max-height 0.2s ease-out, height 0.3s ease-out;
}

.map_list_tasks_content{
  @extend .card_map_wrapper;
  //max-height: calc(80vh - 460px);
  //height: calc(80vh - 460px);
  max-height: calc(100vh - var(--collapsible-height));
  height: calc(100vh - var(--collapsible-height));

  max-width: 1800px;
  overflow-x: auto;

  background-color: transparent;
  padding-bottom: 3px;
  padding-right: 0;
  width: 100%;

  transition: max-height 0.2s ease-out, height 0.3s ease-out;
}

@media (max-width: 768px) {
  body {
    height: 1800px;
  }

  .card_map_wrapper {
    height: 100%;
  }

  .card_map_details{
    height: 100%;
  }

  .grid_map_content {
    grid-area: content_map;
    height: 100%;
  }
}

.card_statics {
  overflow-x: scroll;
  white-space: nowrap;
  max-width: 250px;
  width: max-content;
  padding: 1rem;
}

.details_statistics {
  height: 20px;
  background-color: rgba(0, 0, 0, 0.04);
}

/***********************************************************/
.table_title {
  @extend .base_tasks_settings;
  font-size: 14px;
  min-width: 130px;
  font-weight: bold;
  padding-bottom: 10px;
  margin-top: 150px;
}

.map_table {
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 1px;
}

.map_table thead tr th{
  padding-top: 5px;
  padding-bottom: 1px;
}

.map_table td {
  border: solid 0.1px rgba(0, 0, 0, 0.04);
  border-bottom: none;
  padding: 0 1px 0 10px !important;
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.map_table td:first-child {
  border-left-style: solid;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-bottom: none;
}

.map_table td:last-child {
  border-top-right-radius: 1px;
  background-color: #FFFFFF;
  //border-bottom: solid 0.1px rgba(0, 0, 0, 0.04);
}

/**********************************************************/
.container_map_view {
  display: grid;
  grid-template-columns: 0.7fr 1.3fr;
  grid-template-rows: 1fr;
  gap: 0 0;
  grid-auto-flow: row;
  grid-template-areas:
    "listing mapping";
}

.listing { grid-area: listing; }

.mapping { grid-area: mapping; }

//----------------------------------------------------------------
// general map wrapper styles
.grid_map_sidebar {
  grid-area: sidebar;
  min-width: 220px;
  max-width: 560px;
  height: calc(100vh - 300px);
}

.grid_map_content {
  grid-area: content_map;
  height: calc(100vh - 210px);
}

.grid_map_wrapper {
  background-color: #eeeeee;
  display: grid;
  grid-gap: 1px;
  grid-template-areas: "sidebar" "content_map"
}

.grid_map_box {
  border-radius: 5px;
  //padding: 3px;
}

@media only screen and (min-width: 766px) {
  .grid_map_wrapper {
    grid-gap: 0;
    grid-template-columns: 560px auto;
    grid-template-areas: "sidebar content_map";
    max-width: 2800px;
  }

  .grid_map_sidebar {
    grid-area: sidebar;
    min-width: 220px;
    max-width: 1024px;
    height: calc(100vh - 300px);
  }


}

@media only screen and (max-width: 765px) {
  .grid_map_wrapper {
    grid-gap: 0;
    grid-template-columns: 70vh;
    grid-template-areas:
    "sidebar"
    "content_map";
    max-width: 2800px;
    margin-left: 75px;
  }

  .grid_map_sidebar {
    grid-area: sidebar;
    min-width: 220px;
    max-width: 820px;
    height: calc(115vh - 210px);
  }

  .grid_map_content {
    grid-area: content_map;
    height: calc(50vh - 210px);
  }

  .container {
    margin-left: 0;
  }
}

// task details style
.base_tasks_settings {
  background-color: #FFFFFF;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.tasks_container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 55px 70px 1fr;
  grid-template-areas:
    "listing"
    "statistcs"
    "tasks";
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.tasks_listing {
  @extend .base_tasks_settings;
  grid-area: listing;
}

.tasks_details {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.2fr auto;
  gap: 0 0;
  grid-template-areas:
    "tasks_table_header"
    "tasks_table";
  grid-area: tasks;
  overflow-y: scroll;
  overflow-x: scroll;
}

.tasks_table_header {
  grid-area: tasks_table_header;
}

.tasks_table {
  grid-area: tasks_table;
  max-height: calc(66vh);
  max-width: 1600px;
  width: 1600px;
}



.vehicle_marker_label {
  transform: translate(0, -37%);
  font-family: "Nunito", sans-serif;
  font-size: 11px !important;
  font-weight: bold;
  z-index: 5000;
  -webkit-text-stroke: 1px rgba(0, 119, 255, 0.61);
}

/*
 * Property styles in unhighlighted state.
 */
.fleet_map_propertytest {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 5%;
  color: #263238;
  display: flex;
  font-size: 14px;
  gap: 15px;
  height: 115px;
  padding: 3px;
  position: relative;
  transition: all 0.3s ease-out;
  width: 220px;
  max-width: 300px;
}

.fleet_map_propertytest .fleet_map_content {
  align-items: flex-start;
  justify-content: left;
  width: 210px;
  max-width: 300px;
}

.fleet_map_propertytest .fleet_map_button {
  min-width: 100px;
  width: 100%;
  max-width: 300px;
  padding-top: 7px;
  display: block;
  text-align: -webkit-center;
}

.fleet_map_propertytest .fleet_map_details {
  display: flex;
  flex-direction: column;
  flex: 1;

  .fleet_map_features {
    align-items: flex-end;
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
}

.fleet_map_propertytest .fleet_map_address {
  color: #9E9E9E;
  font-size: 10px;
  margin-bottom: 10px;
  margin-top: 5px;
}

.fleet_map_propertytest .fleet_map_features {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.routes_list_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0 0;
  grid-template-areas:
    "routes_list_header routes_list_buttons";
}
.routes_list_buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0 0;
  grid-area: routes_list_buttons;
}
.routes_list_world { grid-area: 1 / 1 / 2 / 3; }
.routes_list_toggle { grid-area: 1 / 2 / 2 / 3; }
.routes_list_header { grid-area: routes_list_header; }

/****************************************************/
.tasks_statistics {
  @extend .base_tasks_settings;
  grid-area: statistcs;
  height: 390px !important;
  overflow-y: auto;
  overflow-x: auto;
}

/* Statistics */
.div_container_st{
  //max-width: 1800px;
  width: 100%;
  //max-height: 740px;
  height: 100%;
}

.container_st {  display: grid;
  grid-template-columns: 0.2fr 1fr 0.8fr;
  grid-template-rows: 0.2fr 1.7fr 1.1fr;
  gap: 0 0;
  grid-auto-flow: row;
  grid-template-areas:
    "title_st title_st title_st"
    "gauge_st progress_st progress_st"
    "details_st details_st details_st";
  @extend .div_container_st;
  overflow-x: auto;
  padding-bottom: 5px;
}

.title_st {
  grid-area: title_st;
  font-size: 14px;
  font-weight: bold;
  padding-left: 10px;
  padding-top: 1px;
  background-color: rgba(0, 0, 0, 0);
  z-index: 1000;
}

.gauge_st {
  grid-area: gauge_st;
  min-width: 250px;
  width: 250px;
  max-width: 250px;
  min-height: 120px;
  height: 120px;
  max-height: 120px;
  transform: translate(-25px, -20px);
}

.progress_st {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 0 0;
  grid-auto-flow: row;
  grid-template-areas:
    "delivered_progress_st"
    "failed_progress_st"
    "uncompleted_progress_st";
  grid-area: progress_st;
  min-width: 260px;
  max-width: 380px;
  padding-left: 10px;
  padding-top: 10px;
  min-height: 120px;
  height: 120px;
  max-height: 120px;
  transform: translate(-65px, -10px);
}

.delivered_progress_st {
  grid-area: delivered_progress_st;
  min-width: 130px;
}

.failed_progress_st { grid-area: failed_progress_st; }

.uncompleted_progress_st { grid-area: uncompleted_progress_st; }

.details_st {
  display: flex;
  grid-template-rows: 1fr;
  gap: 0 0;
  grid-area: details_st;
  text-align-last: center;
  justify-content: flex-start;
  transform: translate(0, -10px);
  margin-left: 58px;
}

.timer_title_st { grid-area: timer_title_st; }

.distance_title_st { grid-area: distance_title_st; }

.timer_values_st { grid-area: timer_values_st; }

.distance_title-st { grid-area: distance_title-st; }

.distance_unit_toggle {
  width: 125px;
  background-color: #ebf4f8;
  border-radius: 3px;
  height: 10px !important;
  text-align-last: center;

  //border:1px solid #CCC;
  box-shadow: 0 0 5px -1px rgba(0,0,0,0.2);
  cursor:pointer;
  vertical-align:middle;
  max-width: 100px;
  padding: 5px;
  text-align: center;
}
.distance_unit_toggle:hover {
  cursor: pointer;
  background-color: #dee9fb;
}
/****************************************************/
// new react-table container
.card-form-label-container {
  color: rgba(0, 0, 0) !important;
  width: 20px;
  padding-left: 10px !important;
}

.content-container-fixer {
  padding-left: 10px !important;
}

.card-table-driver-button {
  display: flex !important;
  width: 30px;
  justify-content: center !important;
  font-weight: 700 !important;
  padding: 5px;
  border: 0 !important;
  color: rgba(0, 0, 0, 0.53);

  &:hover {
    color: #000000
  }
}

.card-cell-driver-actions {
  text-align-last: center;
  text-align: -webkit-center;
}

// driver map filters
.filters_container {
  display: grid;
  gap: 1rem;
  grid-template-columns: 0.33fr 0.33fr 0.33fr;
  grid-template-areas:
    "global_filter_area metro_area date_range_area";
  padding-bottom: 5px;
}

.filters_container_small {
  @extend .filters_container;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "global_filter_area metro_area";
}

.global_filter_area {
  grid-area: global_filter_area;
  padding-left: 5px;
  padding-right: 5px;
  min-width: 100px;
  max-width: 420px;
  width: 100%;
}

.metro_area {
  grid-area: metro_area;
  padding-left: 5px;
  padding-right: 5px;
  min-width: 100px;
  width: 100%;
  max-width: 320px;
}

.date_range_area {
  grid-area: date_range_area;
  padding-left: 5px;
  padding-right: 5px;
  min-width: 110px !important;
  max-width: 280px !important;
  height: 50px !important;
}

// search footer
.search_footer {
  font-size: 10px;
}

.search_input_component {
  font-size: 12px !important;
  min-width: 60px !important;
  max-width: 280px;
  margin-left: 0 !important;
  height: 38px !important;
}

.custom_wrapper_search_class {
  min-width: 80px !important;
  width: 100% !important;
  max-width: 480px !important;
  font-size: 10px !important;
}

@mixin date_picker_input_fv {
  font-size: 10px !important;
  background-color: $titleBackgroundColor !important;
  height: 38px !important;

  border: 1px solid $inputBorderStrokeColor !important;
  border-right-color: transparent !important;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;

  z-index: 8000;
}

.date_picker_input_class {
  @include date_picker_input_fv;
}

.date_picker_button_class {
  font-size: 18px !important;
  background-color: $titleBackgroundColor !important;
  height: 38px !important;
  transform: translate(-2px, 0);

  border: 1px solid $inputBorderStrokeColor;
  border-left-color: transparent;
  border-radius: 0 4px 4px 0;
}

// this fixes the issue posted here https://github.com/Hacker0x01/react-datepicker/issues/3924
.react-datepicker__aria-live {
  position: absolute;
  clip-path: circle(0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  width: 1px;
  white-space: nowrap;
}

.filter_wrapper_container {
  padding-top: 5px;
  text-align: -webkit-center;
}

// X and Y or Z
@media (min-width: 768px) and (max-width: 1480px), (max-width: 523px)  {
  .filters_container {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    grid-template-rows: 0.6fr 0.2fr 0.2fr;
    grid-template-areas:
    "global_filter_area"
    "metro_area"
    "date_range_area";
    padding-bottom: 5px;
    align-items: center;
    justify-content: space-evenly;
    justify-items: center;
  }

  .metro_area {
    grid-area: metro_area;
    padding-left: 5px;
    padding-right: 5px;
    min-width: 100px;
    width: 100%;
    max-width: 250px !important;
  }

  .search_input_component {
    font-size: 12px !important;
    min-width: 100px !important;
    max-width: 250px;
    margin-left: 0 !important;
    height: 38px !important;
  }

  .date_range_area {
    grid-area: date_range_area;
    min-width: 110px !important;
    max-width: 280px !important;
    height: 45px !important;
  }

  .custom_wrapper_search_class {
    max-width: 242px !important;
  }

  .date_picker_input_class {
    @include date_picker_input_fv;
    min-width: 182px !important;
  }
}

/* redirect component */
.contentText_toast_redirect {
  max-width: 1200px;
  width: 100%;
}
@media (min-width: 768px) and (max-width: 1480px) {
  .date_picker_input_class {
    @include date_picker_input_fv;
    width: 100%;
    min-width: 80px !important;
    max-width: 120px !important;
  }
}

/* spinner */
.middle_center_spinner {
  position: absolute;
  top: 50%;
  left: 55%;
  margin: -50px 0 0 -50px;
}

/* Notes badge */
.BadgeNotesStyle {
  position: relative;
  top: -10px;
  right: 2px;
  border-radius: 50%;
}

/* Redirect popup modal */
.fleet_view_modal_message {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

/*
 * Property styles in htmlAdvancedMarkerStyle
 */
.htmlAdvancedMarkerStyle {
  align-items: center;
  background-color: var(--color);
  border-radius: 10% 10% 50% 50%;
  color: #263238;
  display: flex;
  font-size: 14px;
  gap: 15px;
  height: 33px;
  justify-content: center;
  padding: 2px;
  transition: all 0.3s ease-out;
  width: 23px;
  border-top: 1px solid rgba(0, 0, 0, 0.19);
  border-right: 1px solid rgba(0, 0, 0, 0.19);
  border-left: 1px solid rgba(0, 0, 0, 0.19);
  position: relative;
  z-index: 1;
  opacity: 0.5;
}

.htmlAdvancedMarkerStyle::after {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 6px solid var(--color);
  content: '';
  height: 0;
  left: 50%;
  position: absolute;
  top: 95%;
  transform: translate(-50%, -3px);
  transition: all 0.3s ease-out;
  width: 0;
  z-index: 899;
}

.htmlAdvancedMarkerStyle .icon {
  align-items: center;
  display: flex;
  justify-content: center;
  color: #FFFFFF;
  transform: translate(0, 4.5px);
  z-index: 1;
}

.htmlAdvancedMarkerStyle .icon svg {
  height: 30px !important;
  width: auto;
}

.htmlAdvancedMarkerStyle .details {
  display: none;
  flex-direction: column;
  flex: 1;
}

.htmlAdvancedMarkerStyle .features {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.htmlAdvancedMarkerStyle .features>div {
  align-items: center;
  background: #F5F5F5;
  border-radius: 5px;
  border: 1px solid #ccc;
  display: flex;
  font-size: 10px;
  gap: 5px;
  padding: 5px;
}

/*
 * Property styles in highlighted state.
 */
.htmlAdvancedMarkerStyle.highlight {
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.2);
  height: 80px;
  padding: 8px 15px;
  width: auto;
}

.htmlAdvancedMarkerStyle.highlight::after {
  border-top: 9px solid #FFFFFF;
}

.htmlAdvancedMarkerStyle.highlight .details {
  display: flex;
}

.htmlAdvancedMarkerStyle.highlight .icon svg {
  width: 50px;
  height: 50px;
}
/* [END maps_advanced_markers_html] */
///////////////////////////////////////////////////////
// NEW STYLES
.fv_page_title_function {
  @extend .page-title;

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-size: var(--fontSize) !important;
  font-weight: var(--fontWeight);
  margin-top: var(--marginTop) !important;
  color: $titleFontColor;
  padding-bottom: 1px;
  justify-content: center;
  height: var(--height) !important;
}

.fixed_driver_tasks_title {
  transform: translate(0, -3px);
}

.fixed_driver_tasks_id {
  transform: translate(0, -3px);
}

.float_child {
  float: left;
  padding-right: 2px;
}

.float_status_child {
  clear: left;
  margin-top: -36px;
}

.title_task_others{
  line-height: 81px;
}

.title_tasks_opt {
  border-bottom: 1px solid $titleBottomLineColor;
  width: 100%;
  transform: translate(-15px, 0);
}

.tasks_details_loading {
  display: flex;
  align-items: center;
  height: 82px !important;
  transform: translate(0, -2px);
}

.form-control:focus {
  outline: 0 !important;
  border-color: initial;
  box-shadow: none;
}

.titleStyle {
  border-bottom: 1px solid $titleBottomLineColor;
  background-color: var(--ct-bg-topbar) !important;
}

.containerFixedStyle {
  background-color: #F3F3F3;
}

.fixedFVContent {
  transform: translate(-16px, 0);
}

.fixedInputSearchStyle {
  padding-left: 12px !important;
  background-color: $titleBackgroundColor;
  height: 38px !important;

  border: 1px solid $inputBorderStrokeColor !important;
  border-right-color: transparent !important;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;

  z-index: 8000;
}

// statistics
.statistic_card_padding {
  padding: 2px 12px 2px 12px !important;
}

.fv_default_font_settings {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-size: 13px !important;
  line-height: normal;
}

.statistic_card {
  .statistic_card_title {
    text-align-last: center;
    font-size: 13px;
    transform: translate(0, -8px);
  }

  .statistic_card_icon {
    @extend .card-summary-icon;
    transform: translate(-1px, 2px);
    width: 23px;
    height: 23px;
    background-color: var(--color);
    margin-left: 2px;

    > img {
      transform: translate(-6.6px, -4.8px);
      width: 14px;
      background-color: transparent !important;
    }
  }

  .statistic_card_value {
    color: var(--color);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    display: flex;
    justify-content: center;
    width: 100px;
    max-width: 90px;
    white-space: nowrap;
  }

  height: 85px;
  width: 100%;
  background-color: $titleBackgroundColor;
  border: 1px solid $titleBottomLineColor;
  border-top-color: transparent !important;
  border-top-left-radius: var(--left-border-radius) !important;
  border-top-right-radius: var(--right-border-radius) !important;
}

.statistic_card_pseudo {
  @extend .statistic_card;

  &::before, &::after {
    content: "";
    position: absolute;
    top: 37px;
    right: -6px;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
  }

  &::after{
    border-left: 5px solid $titleBackgroundColor;
    z-index: 3;
    border-bottom: 6px solid transparent;
    border-top: 6px solid transparent;
    transform: translate(-2.5px, 0);
  }

  &::before {
    border-left: 5px solid $titleBottomLineColor;
    z-index: 2;
  }
}

.statistic_metrics{
  .toggle-metrics-container {
    background-color: $titleBackgroundColor;
    border-radius: 6px;
    border: 1px solid $inputBorderStrokeColor !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    z-index: 8000;
  }

  .value_container {
    width: 25px;
    text-align: center;
  }

  .toggle-metrics-btn {
    width: 100%;
    background-color: $titleBackgroundColor;
    color: $titleFontColor;
    height: 37px !important;
    flex-shrink: 0;

    border: 1px solid $inputBorderStrokeColor !important;
    border-top-color: transparent !important;
    border-radius: 0 0 6px 6px;

    &:hover {
      background-color: $titleBottomLineColor
    }
    font-size: 11px;

    display: flex;
    text-align-last: center;
    align-items: center;
    text-align: center;

    align-content: center;
    justify-content: center;
  }

  .collapsed_btn {
    @extend .toggle-metrics-btn;
    border-top-color: $inputBorderStrokeColor !important;
    border-radius: 6px;
    background-color: $titleBackgroundColor !important;
  }

  padding-top: 16px;
  flex-direction: column-reverse;
}

.col-fixed-fv {
  flex: 0 0 auto;
  width: 120px;
}

.progress_st_component {
  display: inline-block;
  transform: translate(0, -4px);
  color: black;
}

.border-right-class {
  border-right: 10px solid rgb(0, 0, 0);
  z-index: 100;
}

.table_details {
  //@extend table;
  @extend .fv_default_font_settings;
  
  .table-th {
    min-width: 10px;
    width: 20px;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0);
  }

  thead {
    padding-left: 10px;
    height: 56px;
    tr {
      th {
        padding: 6px 20px 3px 10px;
        background-color: rgb(255, 255, 255, 0.9);
        border-right: 1px solid rgb(0, 0, 0);
        position: sticky;
        margin-right: 10px;
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
  tbody {
    border-top: 1px solid #1E1E1E;

    tr {
      td {
        padding: 0;
        color: rgba(0, 0, 0);
        height: 35px;
      }
    }
    //tr:hover {
    //  background-color: #f3f3f3 !important;
    //}
  }

  .sortable {
    //border-right-color: $tableHeaderBorderColor !important;
    background-color: rgb(255, 255, 255, 0.9);
    border-right: 1px solid rgb(0, 0, 0);
    color: rgba(0, 0, 0);
    &:before {
      right: .2rem !important;
      left: auto !important;
      top: 12px !important;
      font-size: 14px;
      font-weight: 700;
    }
    &:after {
      right: .2em !important;
      left: auto !important;
      top: 20px !important;
      font-size: 14px;
      font-weight: 700;
    }
  }
}

.no_right_border {
  border-right: none !important;
}

//driver table
//Date and status
.d-flex-table {
  overflow: visible;
}

.driver-table-date_status {
  width: 4px;
  height: 56px;
  flex-shrink: 0;
  background: var(--background-color);
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  margin-right: 4px;

  &:hover {
    width: 8px;
    margin-right: 0;
  }
}

.divContentDate {
  position: sticky;
  margin-left: 6px;
  font-size: 14px;
  color: #727272;
  font-weight: 400;
}

.tooltip-fv {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted var(--background-color);
  z-index: 3;
}

/* tooltip-fv text */
.tooltip-fv .tooltip-content {
  visibility: hidden;
  width: 70px;
  color: rgb(0, 0, 0) !important;
  background-color: rgba(255, 255, 255);
  border: 1px solid $neutralBorderColor;
  border-left: 0 transparent;
  text-align: left;
  padding: 5px 5px;
  margin-left: 7px;
  margin-top: 8px;
  position: absolute;
}

/* Show the tooltip-fv text when you mouse over the tooltip-fv container */
.tooltip-fv:hover .tooltip-content {
  visibility: visible;
  border: 1px solid var(--background-color);
  border-radius: 0 2px 2px 0;
  font-size: 12px;
  color: rgb(0, 0, 0) !important;
}

.driverList-tasks-wrapper {
  .total {
    display: flex;
    justify-content: end;
    padding-right: 7px;
  }
  
  .driver-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: 14px;
    padding: 4px 0 0;
  }
  
  > img {
    padding-right: 3px;
    height: 15px;
  }

  padding: 0 0 0 10px;
}

.row_col_fixer {
  .row {
    padding: 0 !important;

    .col, .col-2, .col-3, .col-4, .col-9 {
      padding: 0 !important;
      margin: 0 !important;
    }
  }
}

.tasks_statistics_dist_and_time {
  max-width: 600px;
  height: 51px;
  font-size: 13px;
  //margin-right: 16px;
  color: $titleFontColor;
  border: 1px solid $titleBottomLineColor;

  @extend .row_col_fixer;

  .main_column_props {
    padding: 9.5px 0 9.8px 12px !important;
  }

  .metric_value_props {
    width: var(--width);
    transform: translate(10px, 0);
  }

  .i_metrics_props {
    color: $iconGrayColor !important;
    font-size: 18px;
  }

  .i_metrics_adjustment {
    transform: translate(-4px, 0);
    width: var(--width);
    padding: 0 5px 0 5px !important;
  }

  .divider {
    border-left: 1px solid $titleBottomLineColor;
  }
}

.tooltip_search_icon {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  color: $iconGrayColor;
  font-size: 16px;
  margin-left: 4px;
  transform: translate(0, -2px);
}

.table_center_content {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateX(-20px);
}

.table_task_time_container {
  width: 90px;
  height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .time_container {
    font-size: 10px !important;
    color: $iconGrayColor;
  }
}

.table_content_fixer {
  transform: translateX(10px);
}

.table_content_fixer {
  transform: translateX(10px);
}
