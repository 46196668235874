@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

.c-multi-select-dropdown {
    // margin:100px auto;
    font-family: "Roboto", sans-serif;
    position: relative;
    // width:120px;
    cursor: pointer;
    // &:hover{
    //     .c-multi-select-dropdown__options{
    //         display:block;
    //     }
    // }

    .c-multi-select-dropdown-select-option {
        color: #050505;
        font-weight: normal;
        font-size: 14px;
    }

    .btn-extra {
        background: #fff;
        color: #050505;
        box-shadow: none;
        border-color: #c3c6ca;
        border-radius: 6px;
    }

    .btn-extra:hover {
        background: #E3E3E4;
        border-color: #c3c6ca;
    }

    .c-multi-select-dropdown__selected {
        border: solid 1px #eee;
        font-size: 14px;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > img {
            height: 18px;
        }
    }

    .c-multi-select-dropdown__options {
        // display:none;
        position: absolute;
        left: 0;
        width: 200px;
        list-style: none;
        cursor: pointer;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06);
        border-radius: 8px;
        min-width: 10rem;
        padding: 0.25rem 0;
        margin-top: 2px;
        font-size: 0.9rem;
        color: var(--ct-dropdown-color);
        text-align: left;
        background-color: var(--ct-dropdown-bg);
        background-clip: padding-box;
        border: 1px solid var(--ct-dropdown-border-color);
        border-radius: 0.25rem;
        z-index: 1000;
    }

    .c-multi-select-dropdown__option {
        display: flex;
        align-items: center;
        padding: 8px 10px;
        cursor: pointer;
        margin: 0 4px 0 4px;
        border-radius: 6px;

        &:hover {
            background-color: #eee;
        }

        &-checkbox {
            margin-right: 6px;
        }
    }

    .c-multi-select-dropdown__option-selected {
        background-color: #f7f8fc;

        &:hover {
            background-color: #f7f8fc;
        }
    }

    .c-multi-select-dropdown__option-checkbox {
        width: 24px;
        height: 24px;
        background: #ffffff;
        border: 1px solid #c3c6ca;
        border-radius: 6px;
    }

    .dropdown-option-label {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #050505;
    }
}

    // .btn-extra {
    //     background: #fff;
    //     color: #050505;
    //     box-shadow: none;
    //     border-color: #c3c6ca;
    //     border-radius: 6px;
    // }

    // .btn-extra:hover {
    //     background: #E3E3E4;
    //     border-color: #050505;
    // }
