//
// Roboto Mono fonts
//

@font-face {
  font-family: "Roboto Mono";
  src: url("../../../fonts/RobotoMono-Regular.ttf");
  src: local("RobotoMono Regular"),
  local("RobotoMono-Regular"),
  url("../../../fonts/RobotoMono-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
