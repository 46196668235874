//
// Roboto fonts
//

@font-face {
  font-family: "Roboto";
  src: url("../../../fonts/Roboto-Light.ttf");
  src: local("Roboto Light"),
  local("Roboto-Light"),
  url("../../../fonts/Roboto-Light.woff") format("woff"),
  url("../../../fonts/Roboto-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../../../fonts/Roboto-Regular.ttf");
  src: local("Roboto Regular"),
       local("Roboto-Regular"),
       url("../../../fonts/Roboto-Regular.woff") format("woff"),
       url("../../../fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../../../fonts/Roboto-Bold.ttf");
  src: local("Roboto Bold"),
       local("Roboto-Bold"),
       url("../../../fonts/Roboto-Bold.woff") format("woff"),
       url("../../../fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}