.new-title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #050505;
}

.new-main-title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    color: #050505;
}

.new-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #737579;
}

.new-text2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #050505;
}

.new-text3 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #737579;
}

.dropdown-toggle-title {
    background-color: #fff;
    color: #050505;
    border-color: #050505;
    border-radius: 6px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    box-shadow: none !important;
    outline: none !important;
    outline-offset: none !important;
}

.dropdown-toggle-title:hover {
    background-color: #E3E3E4 !important;
    color: #050505;
    border-color: #050505;
    border-radius: 6px;
    box-shadow: none !important;
    outline: none !important;
    outline-offset: none !important;
}

.new-table-header {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #050505;
}

.new-dot {
    width: 4px;
    height: 4px;
    background-color: #737579;
    border-radius: 100px;
    margin: 8px;
}
.toggle-title {
    font-weight: 600;
    width: 150px;
}

.offcanvas-body {
    flex-grow: 1;
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
}

.offcanvas-width {
    width: 520px;
}

.gilroy-font-400 {
    font-family: 'Gilroy', sans-serif;
    font-weight: 400;
}

.gilroy-font-300 {
    font-family: 'Gilroy', sans-serif;
    font-weight: 300;
}

.gilroy-font-800 {
    font-family: 'Gilroy', sans-serif;
    font-weight: 800;
}

.roboto-font-700 {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
}

// .offcanvas-end {
//     top: 0;
//     right: 0;
//     width: 520px;
//     border-left: 1px solid transparent;
//     transform: translateX(100%);
// }
