

.form-switch {
  // .form-check-input:not(:checked) {
  //   background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e") !important;
  //   background-color: rgba(60, 141, 188, 0.6);
  // }

  .form-check-input {
    border: none;
  }

  .form-check-input:not(:checked) {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e") !important;
    background-color: gray;
  }

  &.form-switch-sm {
    width: 2em;
    height: 1.112em;
  }

  &.form-switch-md {
    .form-check-input {
      width: 3em;
      height: 1.668em;
    }
  }

  &.form-switch-lg {
    .form-check-input {
      width: 4em;
      height: 2.224em;
    }
  }
}