//
// Gilroy fonts
//

@font-face {
  font-family: "Gilroy";
  src: url("../../../fonts/Gilroy-Light.woff");
  src: local("Gilroy Light"),
  local("Gilroy-Light"),
  url("../../../fonts/Gilroy-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../../fonts/Gilroy-Medium.woff");
  src: local("Gilroy Medium"),
  local("Gilroy-Medium"),
  url("../../../fonts/Gilroy-Medium.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url("../../../fonts/Gilroy-ExtraBold.woff");
  src: local("Gilroy ExtraBold"),
  local("Gilroy-ExtraBold"),
  url("../../../fonts/Gilroy-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}
@font-face {
    font-family: "Gilroy";
    src: url("../../../fonts/Gilroy-Bold.ttf");
    src: local("Gilroy Bold"),
    local("Gilroy-Bold"),
    url("../../../fonts/Gilroy-Bold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
}
