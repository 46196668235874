.bt-accordion-borderless {
    &.accordion {
        .accordion-item {
            border: none;
        }

        .accordion-header {
            margin-top: 0px;

            .accordion-button {
                box-shadow: none;
                background-color: transparent;
                color: rgb(0, 0, 0);
                padding-top: 4px;
                padding-bottom: 4px;

                .accordion-header-title {
                    display: flex;
                    width: 100%;
                    align-items: center;

                    span: {
                        flex: 1;
                    }

                    hr {
                        flex: 1;
                        margin-left: 1rem;
                        opacity: 1;
                    }
                }

                &::after {
                    // filter: opacity(0.5);
                    transform: scale(0.5);
                }

                &:not(.collapsed)::after {
                    transform: scale(0.5) rotate(180deg);
                }
            }
        }
    }
}

// Dark mode
body[data-layout-color="dark"] {
    .accordion-header {

        .accordion-button {

            .accordion-header-title {
                color: rgba(255, 255, 255);

                hr {
                    color: rgba(255, 255, 255);
                }
            }
        }
    }
}