
// Better Trucks cutsom colors
$yellow-green: #82C344;
$cyan-blue-azure: #3C8DBC;

$primary: $cyan-blue-azure;

// scss-docs-start theme-colors-map
$theme-colors: (
  "primary":    $yellow-green,
  "secondary":  $cyan-blue-azure,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
);
// scss-docs-end theme-colors-map

.primary-color {
  color: $cyan-blue-azure;
}

.secondary-color {
  color: $yellow-green;
}

.disabled-color {
  color: rgba(0, 0, 0, 0.13);
  cursor: default !important;
}

// removed excess padding from containers in case of mobile view
@media (max-width: 576px) {
  .container {
    padding: 0px !important;
  }

  .container-fluid {
    padding: 0px !important;
  }
}

// Form controls
.form-control:has(input[type="checkbox"]){
  background-color: transparent !important;
}

.form-control {
  &.is-invalid {
    z-index: auto !important;
  }
}

.div-title-divider {
  position: "absolute";
  font-weight: 600;
  background-color: white;
}

body[data-layout-color="dark"] {
  .div-title-divider{
    background-color: transparent !important;
  }
}

.button-bottom {
  left: 0;
  padding: 17px 0;
  display: flex;
  justify-content: center;

}

.wrapper [data-simplebar] {
  height: 100vh !important;

}
#feedback-modal{
  display: flex;
  justify-content: center;
}

#feedback-modal .modal-content{
  width: 80% !important;
  justify-content: center;
}