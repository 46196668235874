.slider-round {
    height: 10px;

.noUi-target {
    background: #1a191f !important;
    border-radius: 0 !important;
    border: none !important;
    box-shadow: none !important;
  }
  .noUi-horizontal {
    height: 4px !important;
    margin: 10px 0 !important;
  }
  .noUi-connects {
    background-color: #D9D9D9 !important;
    border-radius: 18px !important;
    border: none !important;
  }
  .noUi-connect {
    background-color: #5992ED !important;
  }
  .noUi-handle {
    border: 2px solid #5992ED !important;
    border-radius: 50% !important;
    cursor: pointer !important;
  }
  .noUi-handle:before,
  .noUi-handle:after {
    display: none !important;
  }
  .noUi-handle:focus {
    outline: none !important;
  }
  .noUi-handle.noUi-handle-lower {
    background-color: #FFFFFF !important;
    // box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3) !important;
  }
  .noUi-handle.noUi-handle-upper {
    background-color: #FFFFFF !important;
    // box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3) !important;
  }
  .noUi-horizontal .noUi-handle {
    width: 16px !important;
    height: 16px !important;
  }
  html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
    right: -8px !important;
  }
}