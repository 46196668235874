// 
// custom-forms.scss
//

.form-check-input[type="checkbox"] {
    border-color: #C3C6CA;
    width: 25px;
    height: 25px;
    border-radius: 6px;
}

.form-check-input-new[type="checkbox"] {
    width: 25px;
    height: 25px;
    border-radius: 6px;
}   

@each $color,
$value in $theme-colors {
    .form-checkbox-#{$color},
    .form-radio-#{$color} {
        .form-check-input {
            &:checked {
                background-color: $value;
                border-color: $value;
            }
        }
    }
}

// Dark mode
body[data-layout-color="dark"] {
    .form-check-input[type="checkbox"] {
        border-color: rgba(255, 255, 255);
    }
}